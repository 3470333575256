import { tailwind } from "@theme-ui/presets"
import { merge } from "theme-ui"

export default merge(tailwind, {
  links: {
    primary: {
      color: "blue.6",
      fontSize: "2",
      textDecoration: "none",
      border: "solid 1px",
      borderColor: "blue.6",
      borderRadius: 4,
      py: 2,
      px: 3,
      mr: 3,
      ":hover": {
        color: "white",
        bg: "blue.6",
      },
    },
  },
  styles: {
    h1: {
      fontSize: [4, 5],
    },
    h2: {
      fontSize: [2, 3],
    },
    h3: {
      fontSize: [1, 2],
    },
    a: {
      color: "blue.6",
    },
  },
})
